export default function userAccess(
  state = {
    isFetching: false,
    userRoles: null,
    clpAuthorizationUser: {},
    workerUuid: null,
    workerName: null,
    workerData: null,
    counties: null,
    token365: null,
    accessToken365: null,
    csDropDownData: null,
    vmInfo: null,
    emptySlot: null,
    emptyCalendarSlot: null,
    calendarScheduleInfo: null,
    workerWidgetOutlookData: null,
    outlookModal: false,
    goToManageOutlook: false,
    cwOnlyRole: false,
    activeEntitlements: null,
    refreshLoad: false,
    errorMsgData: null,
    ccUser: false,
    programCodeValue: null
  },
  action,
) {
  switch (action.type) {
    case 'INITIALIZE':
      return Object.assign({}, state, {
        isFetching: false,
      });
    case 'LOGIN_SUCCESS':
      return Object.assign({}, state, {
        isFetching: true,
      });
    case 'LOGIN_REQUEST':
      return Object.assign({}, state, {
        isFetching: false,
        userRoles: null,
        clpAuthorizationUser: {},
        token365: null,
        accessToken365: null,
        workerData: null,
        counties: null,
        userCounty: null,
        workerUuid: null,
        workerName: null,
        csDropDownData: null,
        vmInfo: null,
        emptySlot: null,
        emptyCalendarSlot: null,
        calendarScheduleInfo: null,
        workerWidgetOutlookData: null,
        outlookModal: false,
        goToManageOutlook: false,
        cwOnlyRole: false,
        activeEntitlements: null,
        refreshLoad: false,
        errorMsgData: null,
        ccUser: false,
        programCodeValue: null
      });
    case 'LOGOUT_SUCCESS':
      return Object.assign({}, state, {
        isFetching: false,
        userRoles: null,
        clpAuthorizationUser: {},
        token365: null,
        accessToken365: null,
        workerData: null,
        counties: null,
        userCounty: null,
        workerUuid: null,
        workerName: null,
        csDropDownData: null,
        vmInfo: null,
        emptySlot: null,
        emptyCalendarSlot: null,
        calendarScheduleInfo: null,
        workerWidgetOutlookData: null,
        outlookModal: false,
        goToManageOutlook: false,
        cwOnlyRole: false,
        activeEntitlements: null,
        errorMsgData: null,
        refreshLoad: false,
        ccUser: false,
        programCodeValue: null
      });
    case 'AUTHENTICATE_USER':
      return Object.assign({}, state, {
        isFetching: false,
        clpAuthorizationUser: action.payload,
      });
    case 'GO_TO_MANAGE_OUTLOOK': {
      return Object.assign({}, state, {
        goToManageOutlook: action.goToManageOutlook,
      });
    }
    case 'UPDATE_WORKER_WIDGET_OUTLOOK_CONFIG':
      return Object.assign({}, state, {
        workerWidgetOutlookData: action.workerWidgetOutlookData,
      });
    case 'UPDATE-CW-ONLY-ROLE':
      return Object.assign({}, state, {
        cwOnlyRole: action.cwOnlyRole,
      });
    case 'UPDATE-ACTIVE-ENTITLEMENTS':
      return Object.assign({}, state, {
        activeEntitlements: action.activeEntitlements,
      });
    case 'OUTLOOK_MODAL':
      return Object.assign({}, state, {
        outlookModal: action.outlookModal,
      });
    case 'PROGRAM_CODE_VALUE':
      //ALM_27798
      return Object.assign({}, state, {
        programCodeValue: action.programCodeValue,
      });
    case 'UPDATE_TOKEN':
      return Object.assign({}, state, {
        token365: action.token365,
      });
    case 'UPDATE_EMPTY_SLOT_INFO':
      return Object.assign({}, state, {
        emptyCalendarSlot: action.slotInfo,
      });
    case 'UPDATE_SCHEDULE_INFO':
      return Object.assign({}, state, {
        calendarScheduleInfo: action.scheduleInfo,
      });
    case 'UPDATE_COUNTY':
      return Object.assign({}, state, {
        userCounty: action.countyObject,
      });
    case 'UPDATE_AT_TOKEN':
      return Object.assign({}, state, {
        accessToken365: action.accessToken365,
      });
    case 'UPDATE_TAB':
      return Object.assign({}, state, {
        emptySlot: action.updateTab,
      });
    case 'UPDATE_WORKER-UNIT_UUID':
      return Object.assign({}, state, {
        workerUuid: action.workerid,
        workerName: action.workerName,
      });
    case 'UPDATE_VM_TAB':
      return Object.assign({}, state, {
        vmInfo: action.updateVmInfo,
      });
    case 'UPDATE-WORKER-DATA':
      return Object.assign({}, state, {
        workerData: action.workerData,
      });
    case 'UPDATE-DROPDOWN-DATA':
      return Object.assign({}, state, {
        csDropDownData: action.csDropDownData,
      });
    case 'UPDATE-COUNTIES-DATA':
      return Object.assign({}, state, {
        counties: action.counties,
      });
    case 'UPDATE_CLP_USER_ROLES':
      return Object.assign({}, state, {
        isFetching: false,
        userRoles: action.userRoles,
      });
    case 'UPDATE_CLP_CS_ERR_MSGS':
      return Object.assign({}, state, {
        errorMsgData: action.errorMsgData,
      });
    case 'UPDATE_REFRESH_FLAG':
      return Object.assign({}, state, {
        refreshLoad: action.refreshLoad,
      });
    case 'UPDATE-CC-USER':
      return Object.assign({}, state, {
        ccUser: action.ccUser,
      });
    default:
      return state;
  }
}
import * as React from 'react';
import { config } from '../../../config';
import { MultitenantLoadable } from '@optum-wvie/dynamic-ui-shared/src/utils';
import { get } from 'lodash';

const _ = { get };
const Fragment = (React as any).Fragment;
const tenantDirectoryName = config.tenant.code.toLowerCase();

const withRoutes = WrappedComponent => {
  return class extends React.Component<any, any> {
    constructor(props) {
      super(props);
      this.state = {
        TenantRoutes: undefined,
        BaseRoutes: undefined,
      };
    }

    componentDidMount() {
      Promise.all([
        import('../../' + tenantDirectoryName + '/Root/routes'),
        import('./routes'),
      ]).then(result => {
        const TenantRoutes = result[0].default;
        const BaseRoutes = result[1].default;
        this.setState({
          TenantRoutes,
          BaseRoutes,
        });
      });
    }

    _openIdRedirect = () => {
      const redirectUri = config.openId_loginUrl.replace(
        '{redirectUrl}',
        encodeURIComponent(config.openId_redirectUrl),
      );
      window.location.href = redirectUri;
      return null;
    };

    render() {
      const { TenantRoutes, BaseRoutes } = this.state;
      if (TenantRoutes && BaseRoutes) {
        return (
          <WrappedComponent
            routes={auth => (
              <Fragment>
                <BaseRoutes auth={auth} openIdRedirect={this._openIdRedirect} />
                <TenantRoutes
                  auth={auth}
                  openIdRedirect={this._openIdRedirect}
                />
              </Fragment>
            )}
            {...this.props}
          />
        );
      } else {
        return <WrappedComponent routes={null} {...this.props} />;
      }
    }
  };
};

export default withRoutes(
  MultitenantLoadable(
    () => import('./Root'),
    () => import('../../' + tenantDirectoryName + '/Root/Root'),
  ),
);

import * as _ from 'lodash';

export default function auth(
  state = {
    isFetching: false,
    isAuthenticated: false,
    userAccount: null,
    token: null,
    code: null,
    expiry: null,
    timeout: null,
    accessToken: null,
    skipNextRefresh: null,
    alerts: null,
  },
  action,
) {
  switch (action.type) {
    case 'INITIALIZE':
      return Object.assign({}, state, {
        isFetching: false,
      });
    case 'LOGIN_REQUEST':
      return Object.assign({}, state, {
        isFetching: true,
        isAuthenticated: false,
        code: action.code,
      });
    case 'LOGIN_SUCCESS':
      return Object.assign({}, state, {
        userAccount: action.userAccount,
        token: action.token,
        accessToken: action.accessToken,
        expiry: action.expiry,
        timeout: action.timeout,
        isFetching: false,
        isAuthenticated: true,
        errorMessage: '',
        skipNextRefresh: true,
      });
    case 'LOGIN_FAILURE':
      return Object.assign({}, state, {
        isFetching: false,
        isAuthenticated: false,
        errorMessage: action.message,
      });
    case 'LOGOUT_SUCCESS':
      return Object.assign({}, state, {
        token: null,
        isAuthenticated: false,
        code: null,
        userAccount: null,
        expiry: null,
        accessToken: null,
        timeout: null,
        skipNextRefresh: null,
        alerts: null,
      });

    case 'LOGIN_REFRESH_INITIAL':
      return Object.assign({}, state, {
        isFetching: true,
      });

    case 'LOGIN_REFRESH':
      return Object.assign({}, state, {
        isFetching: false,
        token: action.token,
        accessToken: action.accessToken,
        expiry: action.expiry,
        timeout: action.timeout,
      });
    case 'RECEIVED_NOTIFICATIONS':
      return Object.assign({}, state, {
        alerts: action.alerts,
      });
    case 'CLEAR_SKIP_NEXT_REFRESH':
      return Object.assign({}, state, {
        skipNextRefresh: false,
      });
    default:
      return state;
  }
}

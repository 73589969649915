import * as _ from 'lodash'; // extra functionality
import { combineReducers } from 'redux';

export default combineReducers({
  headerSearchActions,
  clientSearchResults,
  homeWelcomeMesage,
  clpUserRoles,
  resetClpUserRoles,
  getAllRoles
});

function headerSearchActions(
  state = { isSearchInitiated: false, gridStatus: false, gridData: [] },
  action,
) {
  switch (action.type) {
    case 'HEADER_SEARCH_STATUS':
      return {
        ...state,
        isSearchInitiated: action.isSearchInitiated,
        searchFormData: action.searchFormData,
      };

    case 'LOGOUT_SUCCESS':
      return {
        ...state,
        isSearchInitiated: false,
        gridStatus: false,
        gridData: [],
        clpUserRoles: {},
      };

    default:
      return state;
  }
}

function clientSearchResults(state = { clientList: [] }, action) {
  switch (action.type) {
    case 'LOGOUT_SUCCESS':
      return {
        ...state,
        clientList: [],
      };
    default:
      return state;
  }
}

function homeWelcomeMesage(
  state = { message: 'Welcome to Common Landing Portal', hasRoleAccess: true },
  action,
) {
  switch (action.type) {
    case 'UPDATE_WELCOME_MESSAGE':
      return {
        ...state,
        message: action.message,
        hasRoleAccess: action.hasRoleAccess,
      };
    default:
      return state;
  }
}

function clpUserRoles(state = { userRoles: [] }, action) {
  switch (action.type) {
    case 'UPDATE_CLP_USER_ROLES':
      return {
        ...state,
        userRoles: action.userRoles,
      };
    case 'LOGOUT_SUCCESS':
      return {
        ...state,
        userRoles: [],
      };
    default:
      return state;
  }
}

function resetClpUserRoles(state = { userRoles: [] }, action) {
  switch (action.type) {
    case 'REMOVE_CLP_USER_ROLES':
      return {
        ...state,
        userRoles: null,
      };
    default:
      return state;
  }
}

function getAllRoles(state = { allRoles: [] }, action) {
  switch (action.type) {
    case 'GET_ALL_ROLES':
      return {
        ...state,
        allRoles: action.roles,
      };
    default:
      return state;
  }
}

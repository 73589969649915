import * as React from 'react';
import { render } from 'react-dom';
import configureStore from './configureStore';
import { config } from './config';
import { words, get, includes } from 'lodash';
import { setLocale, syncTranslationWithStore } from 'react-redux-i18n';
require('es6-object-assign').polyfill();
require('es6-promise').polyfill();
require('array-findindex-polyfill');
require('array.prototype.fill');

import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.bundle.js';
import './src/styles/scss/blue.scss'
import 'whatwg-fetch';

//Uncomment the following block to start capturing fetch responses from localhost
//NOTE: You must first create a folder called "log"
/*
if (process.env.NODE_ENV === 'development') {
  const originalFetch = (global as any).fetch
  const moment = require('moment-timezone')
  const baseUrl = process.env.PUBLIC_URL
  const fetchWithLogging = (endpoint: string, request) => {
    const timeStamp = moment().format('YYYYMMDDHHmmssSSS')
    return originalFetch(endpoint, request).then(response => {
      const responseClone = response.clone()
      response.json().then(response => {
        const fileName = 'fetchResponse-'
          .concat(timeStamp)
          .concat('-')
          .concat(request.method)
          .concat(
            endpoint
              .substring(
                endpoint.indexOf('/api/') > -1
                  ? endpoint.indexOf('/api/')
                  : endpoint.length
              )
              .replace('http://', '')
              .replace('https://', '')
              .replace('?', '')
              .replace(new RegExp('/', 'g'), '-')
          )
          .concat('.json')

        const logRequest = {
          fileName,
          content: response
        }
        originalFetch(baseUrl + '/devlog', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(logRequest)
        })
      })
      return responseClone
    })
  }
  ;(global as any).fetch = fetchWithLogging
  ;(window as any).fetch = fetchWithLogging
}
*/
/**
 * Move this code form index.prod.html and reason main.js file need to cached and injected dynamically.
 * after that only crf js need to load.
 */
// const body = document.getElementsByTagName('body')[0];
// const jquery = document.createElement('script');
// jquery.type = 'text/javascript';
// jquery.src = 'https://ajax.googleapis.com/ajax/libs/jquery/2.2.3/jquery.min.js';
// body.appendChild(jquery);
// const crf = document.createElement('script');
// crf.type = 'text/javascript';
// crf.src = 'https://js.arcgis.com/3.16/';
// body.appendChild(crf);
// commented bellow code due to browser console angular error
// const crfMap = document.createElement('script')
// crfMap.type = 'text/javascript'
// crfMap.src = 'https://resourcefinder-stg.optum.com/app/api/widget/map.js'
// body.appendChild(crfMap)
//---END---

Number.isNaN =
  Number.isNaN ||
  function(value) {
    return value !== value;
  };
import Root from './components/base/Root';
// import { getStaticTranslations } from './actions'
//TODO: rearrange the imports across the project

const _ = { words, get, includes };

const supportedLocales = config['languages'].map(e => e.locale);
const store = configureStore(config.environment, config.tenant.code);
store.dispatch({ type: 'INITIALIZE' });
syncTranslationWithStore(store);
// store.dispatch(getStaticTranslations(supportedLocales) as any)

const browserLocale =
  _.get(window, 'navigator.userLanguage') ||
  _.get(window, 'navigator.language');
let defaultLocale = browserLocale
  ? _.words(browserLocale)[0].toLowerCase()
  : 'en';
if (!_.includes(supportedLocales, defaultLocale)) {
  defaultLocale = 'en';
}
store.dispatch(setLocale(defaultLocale));

render(<Root store={store} />, document.getElementById('root'));

import { createStore, applyMiddleware } from 'redux';
import * as _ from 'lodash';
import thunkMiddleware from 'redux-thunk';
import reducer from './reducers';
import SessionStorage from '@optum-wvie/dynamic-ui-framework/src/sessionStorage';
import { routerMiddleware, push } from 'react-router-redux'
import { i18nReducer } from 'react-redux-i18n';
import { createBrowserHistory } from 'history'
import ReactMatomo from 'react-piwik'
import { config } from './config'

//const stringify: Function = require('fast-json-stable-stringify')

const isMatomoEnabled = (config.matomoUrl && config.matomoSiteId && process.env.NODE_ENV === 'production')
const matomo = isMatomoEnabled ? 
  new ReactMatomo({
    url: config.matomoUrl,
    siteId: config.matomoSiteId,
    //trackErrors: true,
    jsFilename: 'matomo.js',
    phpFilename: 'piwik.php'
  })
  : null;

if (isMatomoEnabled) {
  ReactMatomo.push(['trackPageView'])
}

export const history = isMatomoEnabled ? matomo.connectToHistory(createBrowserHistory()) : createBrowserHistory()

const stringify = JSON.stringify;

const middlewares = [thunkMiddleware, routerMiddleware(history)]

const configureStore = (environment, tenantCode) => {
  const stateLSKey = ('IE-' + environment + '-CLP-' + tenantCode + '-STATE').toUpperCase();
  const persistedState = JSON.parse(SessionStorage.getItem(stateLSKey) || '{}');
  //TODO: Faster parse?

  const store = createStore(
    reducer,
    persistedState,
    applyMiddleware(...middlewares),
  );

  store.subscribe(
    _.debounce(() => {
      SessionStorage.setItem(stateLSKey, stringify(store.getState()));
    }, 500),
  );

  return store;
};

export default configureStore;

import { combineReducers } from 'redux';
import { includes, isEmpty, isArray, find, clone } from 'lodash';
const _ = { includes, isEmpty, isArray, find, clone };

export default combineReducers({
  myMessagesError,
});

function myMessagesError(state = { errorMessage: [], errorList: [] }, action) {
  switch (action.type) {
    case 'UPDATE_MYMESSAGES_Error':
      let newErrorMessage = _.clone(state.errorMessage) || [];
      const errorList = _.clone(state.errorList) || [];
      let serviceCalling;
      if (_.isArray(action.message)) {
        newErrorMessage = action.message;
        serviceCalling = false;
      } else {
        if (action.message === 'Service Calling') {
          serviceCalling = true;
        } else {
          serviceCalling = false;
          const failedToFetch =
            includes(action.message, 'Failed to fetch') ||
            includes(action.message, 'Failed with status');
          const errorObject = _.find(errorList, error => {
            if (failedToFetch) {
              if (includes(action.message, error.API_NM)) {
                return error.XCPT_TYP === 'SERVICENOTAVAILABLE';
              }
            } else {
              return (
                includes(action.message, error.API_NM) &&
                includes(action.message, error.XCPT_TYP)
              );
            }
          });
          if (errorObject) {
            newErrorMessage.push(errorObject);
          }
          // 502 scenarios
          if (includes(action.message, 'Bad Gateway')) {
            let errorObjList = {
              errorCode: 'CLP-GENE-ERR001',
              errorDescription:
                'This functionality is currently not available. Please try again later or contact the Technical Call Center at 1-844-414-5112 for immediate assistance.',
            };
            let errorMsg = _.find(newErrorMessage, {
              errorCode: 'CLP-GENE-ERR001',
            });
            if (errorMsg === undefined) {
              newErrorMessage.push(errorObjList);
            }
          }
        }
      }
      return {
        ...state,
        errorMessage: newErrorMessage,
        serviceCalling: serviceCalling,
      };
    case 'DELETE_MYMESSAGES_Error':
      return {
        ...state,
        errorMessage: [],
      };
    case 'UPDATE_ERROR_LIST':
      const newErrorList = action.errorList || [];
      return {
        ...state,
        errorList: newErrorList,
      };
    case 'LOGOUT_SUCCESS':
      return { errorMessage: [], errorList: [] };
    default:
      return state;
  }
}
